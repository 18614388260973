import TrackHero from '../components/track/TrackHero';
import TrackFeatures from '../components/track/TrackFeatures';
import HowItWorks from "../components/track/HowItWorks";
import BottomSection from "../components/track/BottomSection";
import Testimonials from "../components/track/Testimonials";
import { testimonials } from '../data/testimonials';
import { Helmet } from 'react-helmet';



const Track = () => {
    return (
        <>
            <Helmet>
                <title>Track Phone Location by Phone Number, Mobile Number Tracker</title>
                <meta name="description" content="Track the location of any phone number with the Device Tracker. Ensure the safety of your loved ones and devices with reliable mobile number tracking." />
            </Helmet>
            <TrackHero />
            <TrackFeatures />
            <HowItWorks />
            <BottomSection />
            <Testimonials testimonialsData={testimonials} />
        </>
    );
}

export default Track;