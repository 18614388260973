import React, { useEffect, useState } from 'react';
import { DtpUtils } from '../../utils/DtpUtils';
import { contactFormTopic } from '../../data/contactFormTopic';
import Spinner from '../sharedComponents/Spinner';
import { useForm } from "react-hook-form";
import styles from '../../styles/help.module.css';


const ContactForm = () => {

    const [userEmail, setUserEmail] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [isSuccess, setisSuccess] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const form = useForm();
    const { register, handleSubmit, formState } = form;
    const { errors } = formState;


    useEffect(() => {
        let email = localStorage.getItem('email');
        if (email && DtpUtils.validateEmail(email)) {
            setUserEmail(email);
        }
        console.log('EmailForm');
    }, []);


    async function sendMessage(email, subject, body) {
        setEmailErrorMessage('');
        setisLoading(true);

        var data = {
            email: email,
            subject: subject,
            body: body
        };

        const response = await fetch(process.env.REACT_APP_PUBLIC_DEVICE_URL + 'support',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });

        setisLoading(false);
        if (!response.ok) {
            setEmailErrorMessage("Something went wrong. Please try again later.");
        } else {
            setisSuccess(true);
        }
    }

    function onEmailSubmit(data) {
        // e.preventDefault();
        setEmailErrorMessage('');
        const target = data;
        console.log("Success Email send");
        sendMessage(data.email, data.subject, data.body);
    }

    return ( 
        <div >
            {isSuccess ?
                <h2>Your message has been submitted and one of our agents will be in touch within 24 hours.</h2>
                :
                <>
                    {emailErrorMessage ?
                        <span className='errorMessage'>{emailErrorMessage}</span>
                        : null}
                    <form onSubmit={handleSubmit(onEmailSubmit)} className={styles.formGrid} noValidate>
                        <label htmlFor="subject">Select subject</label>
                        <select
                            id="subject"
                            className={styles.formSelect}
                            {...register("subject", { required: "Select subject is required" })}
                        >
                            <option></option>
                            {contactFormTopic.map((t) => (
                                <option key={t.id} value={t.content}>{t.content}</option>
                            ))}
                        </select>
                        <p className={styles.errorText}>{errors.subject?.message}</p>
                        <label htmlFor="email">Your email address</label>

                        <input
                            type="email"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email format",
                                }
                            })}
                            id="email"
                            placeholder= "Your email address"
                            defaultValue={userEmail}
                            className={styles.formSelect}
                        />
                        <p className={styles.errorText}>{errors.email?.message}</p>
                        <label htmlFor="body">How can we help you</label>
                        <textarea
                            {...register("body", {
                                minLength: 5,
                                required: "Message field should not be empty and contain at least 5 characters!",

                            })}
                            id="body"
                            placeholder= "Your message"
                            className={styles.formSelect}
                        />
                        {errors.body && errors.body.type === "minLength" && (
                            <span className={styles.errorText} role="alert">Message field should not be empty and contain at least 5 characters!</span>
                        )}
                        <p className={styles.errorText}>{errors.body?.message}</p>
                        {isLoading ?
                            <div className={styles.spinnerContainer}>
                                <Spinner />
                            </div>

                            : null}
                        <button type="submit" className={styles.sendButton}>Send message</button>
                    </form>
                </>
            }
        </div>
     );
}
 
export default ContactForm;