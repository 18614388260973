import faqs from '../assets/images/faqs.webp';
import styles from '../styles/faqs.module.css';
import { Helmet } from 'react-helmet';


const FAQ = () => {
    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <div className="dtpContainer">
                <title>FAQ - Device Tracker Plus</title>
                <div className={styles.mainPageCard}>
                    <h1 className={styles.faqHeading}>
                        <img src={faqs} width={50} height={50} alt="faq_icon" className={styles.faqIcon} />
                        Frequently Asked Questions</h1>
                    <div className={styles.faqContainer}>
                        <details>
                            <summary>
                                How can I contact Device Tracker Plus?
                            </summary>
                            <p>
                                If you need to contact us you can do so <a href="/help/" className={styles.hightLightText}>here</a>. A member of our support team will get back to you within 24 hours.
                            </p>
                        </details>
                        <details>
                            <summary>
                                Can I use this to track a device without the owners consent?
                            </summary>
                            <p>
                                <p>At Device Tracker Plus, our primary concern is to provide a service to assist subscribers who want to keep their loved ones safe, by giving them the ability to locate them.</p>
                                <br />
                                <p><span className={styles.semiboldUnderline}>This service should never be misused in an attempt to track anonymously or without the device user’s knowledge.</span></p>
                                <br />
                                <p>For our service to work, the app will need to be added to the device you wish to track, for this to occur you will need the full consent of the device owner. Once installed the app icon will be visible on the device.</p>
                                <br />
                                <p>Please be aware that our service should never be used to track a device without the consent of the device owner. This is firmly against our values and our policy as stated in our <a href="/terms/" className={styles.hightLightText}>Terms of Service</a> that you will need to agree to prior to using the Device Tracker Plus service.</p>
                                <br />
                                <p>If you are tracking someone who hasn’t consented, please make us aware so we can cancel your account.</p>
                            </p>
                        </details>
                        <details>
                            <summary>
                                How do I cancel my subscription?
                            </summary>
                            <p>
                                <p className={styles.semiBold}>Subscribing through the Device Tracker Plus website</p>
                                <br />
                                <p>If you have subscribed through the Device Tracker Plus website, not through Google or Apple, and would like to cancel please <a href="/help/" className={styles.hightLightText}> click here</a>. Enter the email address you used to subscribe with, this will automatically cancel the subscription for you. You will still have access to the service until the end of your billing period, however your payment method will have been removed and no further payments will be taken. An automated email will be sent to your email address to confirm this has been processed. </p>
                                <br />
                                <p><a href="/help/" className={styles.hightLightText}>Cancel Account</a></p>
                                <br />
                                <p>If you don’t remember the email address used to register with, or our automated system informs you that we don’t have an account linked to the email address you have entered, don’t worry, this is not an issue. Just choose the <a href="/help/" className={`${styles.linkOrange}`}> ‘I have a question about my subscription’  </a>  and complete the form. Please provide the last 4 digits of the card you used to pay with to assist us in locating your account. We will be in contact as quickly as possible to confirm your cancellation.  </p>
                                <br />
                                <p>Please note that the above cancellation link is only for members who have purchased using their credit or debit card. </p>
                                <br />
                                <p className={styles.semiBold}>Subscribing through Apple or Google</p>
                                <br />
                                <p>If you have purchased via your device in the App Store or Google Play, then you will need to cancel your mobile subscription, otherwise you will continue to be billed. You can find out how to cancel through the links below:</p>
                                <br />
                                <p className={styles.semiBold}>IOS: <a href="https://support.apple.com/en-gb/HT202039" className={styles.hightLightText}>Apple Store</a></p>
                                <p className={styles.semiBold}>Android: <a href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en%22%20\t%20%22_self" className={styles.hightLightText}>Googleplay</a></p>
                                <br />
                                <p>We’re sorry to see you go!</p>
                            </p>
                        </details>
                        <details>
                            <summary>
                                Can I find my lost phone with Device Tracker Plus?
                            </summary>
                            <p>
                                <p>Unfortunately, there is no simple yes or no for this question, as there are two different scenarios:</p>
                                <br />
                                <p><span className={styles.semiboldUnderline}>I lost my device before signing up to Device Tracker Plus</span></p>
                                <br />
                                <p>Unfortunately, Device tracker plus must be installed on your device before it is lost, as this allows our technology to link with your location settings. There is no way of sending the app to a device that has already been lost to try and track it. </p>
                                <br />
                                <p><span className={styles.semiboldUnderline}>I lost my device after signing up to Device Tracker Plus</span></p>
                                <br />
                                <p>If you’ve lost your device and have our Device Tracker Plus app installed there’s no need to worry, you should be able to locate it in just a few simple steps.</p>
                                <br />
                                <p>If you lose your device you have two options to locate it:</p>
                                <ol className={styles.faqList}>
                                    <li>Ask any other member of your Device Tracker Plus group to use their app to track the current location of your for you.</li>
                                    <li>Install the app onto another device you have access to and enter your code to join the group from that device. You can then use it to track the device you’ve lost.</li>
                                </ol>
                            </p>
                        </details>
                        <details>
                            <summary>
                                How many devices can I install the app on?
                            </summary>
                            <p>
                                You can install Device Tracker Plus on up to 5 devices using just one account. This makes it easy to keep track of all your Android & Apple devices, as well as the devices of anyone else you want to keep safe.
                            </p>
                        </details>
                        <details>
                            <summary>
                                How accurate is the tracking?
                            </summary>
                            <p>
                                The app uses GPS technology to provide location information, and you’ll usually be able to see the location of any registered device to within 10 feet. However, the accuracy of the tracking may depend on several factors, including your handset, network connection, and where you are. Nevertheless, the app will always try to provide you with the most accurate location information possible.
                            </p>
                        </details>
                        <details>
                            <summary>
                                Can I track my kids?
                            </summary>
                            <p>
                                Yes, you can use Device Tracker Plus to track your children’s devices and ensure their safety. Simply install the app on their device and you’ll be able to see their location in real-time.
                            </p>
                        </details>
                        <details>
                            <summary>
                                How do I know if I’m being tracked?
                            </summary>
                            <p>
                                Yes, you can use Device Tracker Plus to track your children’s devices and ensure their safety. Simply install the app on their device and you’ll be able to see their location in real-time.
                            </p>
                            <p>
                                Everyone you add to your group through the unique code will be able to see the locations of the other members, as well as view their own location history.
                            </p>
                            <p>
                                Whether it’s your child, employee or elderly relative, they will be aware that Device Tracker Plus has been installed on their device. As with most apps, the icon will appear on the screen and they will be able to open it and see the information on screen. They will not be able to access your online account unless you give them permission.
                            </p>
                            <p>
                                <span className={styles.semiBold}>Device Tracker Plus is not a tool for secret or anonymous tracking.</span> If you believe that you are being tracked anonymously or without your consent, please check your phone for the Device Tracker Plus icon. If you can see the icon, simply delete it from your phone to stop the tracking of your device.
                            </p>
                            <p>
                                If you would like further advice or assistance please contact us <a href="/help/" className={styles.hightLightText}>here</a>.
                            </p>
                        </details>
                        <details>
                            <summary>
                                What if my device is turned off or has no internet connection?
                            </summary>
                            <p>
                                Device Tracker Plus requires your device to be turned on and have an active internet connection to provide location tracking. If your device is turned off or has no internet connection, the app will not be able to communicate with the servers, and you will not be able to track its location. However, you will be able to see the device’s location history, and as soon as the device is turned back on and has a stable internet connection, the app will begin tracking its location in real time again.
                            </p>
                        </details>
                        <details>
                            <summary>
                                What information do you hold on me?
                            </summary>
                            <p>
                                At Device Tracker Plus we feel it is our responsibility to handle our customer data discreetly and responsibly. You can see the information we hold and how it is handled in our <a href="/policy/" className={`${styles.linkOrange}`}> privacy policy</a>. This information is available before and after purchase and we recommend you downloading a copy of this for your records.
                            </p>
                        </details>
                        <details>
                            <summary>
                                Who can use Device Tracker Plus?
                            </summary>
                            <p>
                                Device Tracker can be used by a variety of people, depending on their needs:
                            </p>
                            <p>
                                <span className={styles.semiBold}>Parents </span>– Device Tracker was originally designed with parents in mind. You can install the app on their child’s phone to ensure their safety while still giving them the freedom of having a smartphone. As parents are often the owners of the device, as well as the guardian of the person being tracked, installing Device Tracker on their child’s phone, is safe, legal, and in line with the app’s terms of use.
                            </p>
                            <p>
                                <span className={styles.semiBold}>Business owners </span>– Many businesses use our service to keep track of field workers or lone workers. As the device often belongs to the business, the business owner has the authority to install our app as a way of ensuring their staff are safe and using company property as they have authorised.
                            </p>
                            <p>
                                <span className={styles.semiBold}>Carers </span>– Installing Device Tracker on the device of an elderly or unwell relative can help to ensure that they maintain their independence while you can ensure their safety.
                            </p>
                            <p>
                                <span className={styles.semiBold}>Globe Trotters </span>– Whether they’re going to university or travelling the world, Device Tracker Plus is a great way to stay connected with your loved ones when they decide to spread their wings, so you don’t need to panic if they forget to call!
                            </p>
                        </details>
                        <details>
                            <summary>
                                Does the tracking work anywhere?
                            </summary>
                            <p>
                                Device Tracker Plus works anywhere in the world where there is GPS coverage, which means it works pretty much everywhere. So, no matter where in the world your loved ones are, you’ll be able to check their location and make sure they’re safe.
                            </p>
                        </details>
                        <details>
                            <summary>
                                How long is the contract?
                            </summary>
                            <p>
                                There is no contract for Device Tracker Plus. You can cancel at any time without any penalties or fees.
                            </p>
                        </details>
                        <details>
                            <summary>
                                Why do I need to verify my phone number on DTP?
                            </summary>
                            <p>
                                Verifying your phone number with Device Tracker Plus is a quick step to improve account security. While respecting your privacy, verifying your phone number means you can successfully recover your account when switching devices. Your trust and data security matter to us, and this verification is an essential part of maintaining a secure Device Tracker Plus experience.
                            </p>
                        </details>
                    </div>

                    <p className={styles.helpParagraph}>
                        For help with all aspects of Device Tracker Plus, visit the <a href="/help/" className={styles.hightLightText}>Help</a> section.
                    </p>
                </div>
            </div>
        </>
    );
}

export default FAQ;