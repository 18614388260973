import styles from '../../styles/policy.module.css';


const ProcessingData = () => {
    return (
        <div>
            <h4 className={styles.sectionTitleOrange}>How we collect your personal data</h4>
            <p className={styles.sectionSubtitle}>Set out below is a description of the ways we intend to use your personal data and the legal grounds on which we will process such data. We have also explained what our legitimate interests are where relevant.</p>
            <p className={styles.sectionSubtitle}>We may process your personal data for more than one lawful ground, depending on the specific purpose for which we are using your data. Please email us at<a href="mailto:support@devicetrackerplus.zendesk.com" className={`${styles.linkOrange}`}> support@devicetrackerplus.zendesk.com</a> if you need details about the specific legal ground, we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
            <table className={styles.contactTable}>
                <thead>
                    <tr style={{ backgroundColor: '#F1F1F1'}}>
                        <th className="py-5 border-2">Purpose/Activity</th>
                        <th className="py-5 border-2">Type of data</th>
                        <th className="py-5 border-2">Lawful basis for processing</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={styles.tdBody}>To register you as a new customer</td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Identity</li>
                                <li>(b) Contact</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>Performance of a contract with you</td>
                    </tr>
                    <tr style={{ backgroundColor: '#F1F1F1'}}>
                        <td className={styles.tdBody}>To process and deliver your order including:
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Manage payments, fees and charges</li>
                                <li>(b) Collect and recover money owed to us</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Identity</li>
                                <li>(b) Contact</li>
                                <li>(c) Financial</li>
                                <li>(d) Transaction</li>
                                <li>(e) Marketing and Communications</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Performance of a contract with you</li>
                                <li>(b) Necessary for our legitimate interests to recover debts owed to us</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.tdBody}>To manage our relationship with you, which will include:
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Notifying you about changes to our terms or privacy policy</li>
                                <li>(b) Asking you to leave a review or take a survey</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Identity</li>
                                <li>(b) Contact</li>
                                <li>(c) Profile</li>
                                <li>(d) Usage</li>
                                <li>(e) Marketing and Communications</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Performance of a contract with you</li>
                                <li>(b) Necessary for our legitimate interests to study how customers use our products/services, to develop them and grow our business</li>
                            </ul>
                        </td>
                    </tr>
                    <tr style={{ backgroundColor: '#F1F1F1'}}>
                        <td className={styles.tdBody}>To enable you to take part in a prize draw, competition or complete a survey</td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Identity</li>
                                <li>(b) Contact</li>
                                <li>(c) Profile</li>
                                <li>(d) Usage</li>
                                <li>(e) Marketing and Communications</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Performance of a contract with you</li>
                                <li>(b) Necessary for our legitimate interests to study how customers use our products/services, to develop them and grow our business</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.tdBody}>To administer and protect our business and our site (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Identity</li>
                                <li>(b) Contact</li>
                                <li>(c) Technical</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Necessary for our legitimate interests for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise</li>
                                <li>(b) Necessary to comply with a legal obligation</li>
                            </ul>
                        </td>
                    </tr>
                    <tr style={{ backgroundColor: '#F1F1F1'}}>
                        <td className={styles.tdBody}>To deliver relevant content and advertisements to you and measure and understand the effectiveness of our advertising</td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Identity</li>
                                <li>(b) Contact</li>
                                <li>(c) Profile</li>
                                <li>(d) Usage</li>
                                <li>(e) Marketing and Communications</li>
                                <li>(f) Technical</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>Necessary for our legitimate interests to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy</td>
                    </tr>
                    <tr>
                        <td className={styles.tdBody}>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(d) Usage</li>
                                <li>(f) Technical</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>Necessary for our legitimate interests to define types of customers for our products and services, to keep our site updated and relevant, to develop our business and to inform our marketing strategy</td>
                    </tr>
                    <tr style={{ backgroundColor: '#F1F1F1'}}>
                        <td className={styles.tdBody}>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                        <td className={styles.tdBody}>
                            <ul style={{listStyle: 'none'}}>
                                <li>(a) Identity</li>
                                <li>(b) Contact</li>
                                <li>(c) Profile</li>
                                <li>(d) Usage</li>
                                <li>(f) Technical</li>
                            </ul>
                        </td>
                        <td className={styles.tdBody}>Necessary for our legitimate interests to develop our products/services and grow our business</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ProcessingData