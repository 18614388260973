import FamilyTrackingHero from "../components/campaings/FamilyTrackingHero";
import CampaignFeatures from "../components/campaings/CampaignFeatures";
import HowItWorks from "../components/track/HowItWorks";
import CampaignBottomSection from "../components/campaings/CampaignBottomSection";
import Testimonials from '../components/track/Testimonials';
import { Helmet } from 'react-helmet';
import { bottomFamlilyTrackingFeatures } from "../data/bottomFamlilyTrackingFeatures";
import { familyTrackingTestimonials } from "../data/familyTrackingTestimonials";
import { familyTrackingFeaturesList } from "../data/familyTrackingFeaturesList";

const FamilyTracking = () => {
    return (
        <>
            <Helmet>
                <title>Family Location Tracking App for Parents - Device Tracker Plus</title>
                <meta name="description" content="Track your child or teenager with our accurate family location tracker app. Ensure your family is safe while allowing them independence. Download now." />
            </Helmet>
            <FamilyTrackingHero />
            <CampaignFeatures sectionTitle="Track Your Family’s Location Anytime, Anywhere" features={familyTrackingFeaturesList}/>
            <HowItWorks />
            <CampaignBottomSection sectionHeading="Why Parents Choose Device Tracker Plus As Their Family Locator App" features={bottomFamlilyTrackingFeatures} />
            <Testimonials testimonialsData={familyTrackingTestimonials} />
        </>
    );
}

export default FamilyTracking;