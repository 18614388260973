import React, { useState, lazy, Suspense } from 'react';
import heroImageDesk from '../../assets/images/heroHome_desk.webp';
import heroImageMobile from '../../assets/images/heroHome_mobile.webp';

import Spinner from '../sharedComponents/Spinner';


const PhoneVerificationComponent = lazy(() => import('../sharedComponents/PhoneVerification'));
const HeroImageComponent = lazy(() => import('../sharedComponents/HeroImageComponent'))

const HomeHero = () => {

    const [showSearchInput, setShearchInput] = useState(true);

    const togglePhoneVerificationPopup = () => {
        setShearchInput(true);
    }
    return (
        <div className="mainBgc">
            <div className="mainContainer">
                <div className="heroContainer">
                    <h1 className="heroHeading">Get <span className="hightLightText">peace</span> of mind with
                        <br></br>the world’s <span className="hightLightText">#1</span><br></br> device tracking app.</h1>
                    <h3 className="secondaryHeading smallVerticalMargin">Over <span className="hightLightText">5 million</span> users worldwide.</h3>
                    <Suspense fallback={<Spinner />}>
                        {showSearchInput && <PhoneVerificationComponent showPhoneInput={togglePhoneVerificationPopup} />}
                    </Suspense>
                </div>
                <Suspense fallback={<Spinner />}>
                    <HeroImageComponent desktopImage={heroImageDesk} mobileImage={heroImageMobile} />
                </Suspense>
            </div>
        </div>
    );
}

export default HomeHero;