import { appInsights } from "../components/azureAppInsights/azureAppInsights";

export class TagManagerUtils {

    // Create the ecommerce object
    static buildEcommerceObject() {
        let ecommerce = {
            transaction_id: localStorage.getItem('userId'),
            value: localStorage.getItem('amountPaid') ?? 0.0,
            currency: localStorage.getItem('currency') ?? 'USD',
            items: [
                {
                    item_id: localStorage.getItem('priceId') ?? 'unknown',
                    item_name: localStorage.getItem('priceId') ?? 'unknown',
                    price: localStorage.getItem('amountPaid') ?? 0.0,
                    quantity: 1
                }]
        };
        appInsights.trackTrace({ message: 'TAGMANAGERUTILS: ecommerce object created', properties: ecommerce });
        return ecommerce;
    }

    // Create the full onPayment event object
    // including the ecommerce object
    static buildOnPaymentEvent() {
        let onPaymentData = {
            event: 'onPayment',
            amountPaid: localStorage.getItem('amountPaid'),
            paymentId: localStorage.getItem('userId'),
            currency: localStorage.getItem('currency'),
            email: localStorage.getItem('email'),
            ecommerce: TagManagerUtils.buildEcommerceObject(),

        };
        appInsights.trackTrace({ message: 'TAGMANAGERUTILS: onPaymentData object created', properties: onPaymentData });
        return onPaymentData;
    }

    static sendOnPaymentEventIfNotSentBefore() {

        if (!window.dataLayer) {
            console.warn('AHTUNG! window.dataLayer is not defined!');
            appInsights.trackTrace({ message: 'TAGMANAGERUTILS: window.dataLayer is not defined!' });
        }

        if (localStorage.getItem('gtmOnPaymentSent') === 'YES') {
            console.log('gtmOnPaymentSent', 'Already sent!');
            appInsights.trackTrace({ message: 'TAGMANAGERUTILS: gtmOnPaymentSent, Already sent!' });
            return;
        }

        window.dataLayer = window.dataLayer || [];

        let onPaymentData = {
            ...TagManagerUtils.buildOnPaymentEvent(),
            eventCallback: (id) =>  { 
                localStorage.setItem('gtmOnPaymentSent', 'YES');
                console.log('onPayment event sent to GTM', onPaymentData);
                appInsights.trackTrace({ message: 'TAGMANAGERUTILS: onPayment event sent to GTM', properties: onPaymentData });
            },
            eventTimeout: 2000
        };

        window.dataLayer.push(onPaymentData);
    }

    static sendEventWithEcommerceData(eventName) {

        if (!window.dataLayer) {
            console.warn('AHTUNG! window.dataLayer is not defined!');
            appInsights.trackTrace({ message: 'TAGMANAGERUTILS: window.dataLayer is not defined!' });
        }

        window.dataLayer = window.dataLayer || [];

        let evt = {
            event: eventName,
            ecommerce: TagManagerUtils.buildEcommerceObject(),
        }
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push(evt);
        console.log('event sent to GTM', evt);
        appInsights.trackTrace({ message: 'TAGMANAGERUTILS: event sent to GTM', properties: evt });
    }

}