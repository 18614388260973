import React, { useEffect, useState } from 'react';
import { DtpUtils } from '../../utils/DtpUtils';
import styles from '../../styles/cancel.module.css';

const EmailForm = ({ onSubmit, btnLabel, errorMessage }) => {
    const [userEmail, setUserEmail] = useState('');
    const [secondUserEmail, setSecondUserEmail] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [userEmailsMatch, setuserEmailsMatch] = useState(false);
    const [emailMatchError, setEmailMatchError] = useState('');

    useEffect(() => {
        var email = localStorage.getItem('email');
        if (email && DtpUtils.validateEmail(email)) {
            setUserEmail(email);
        }
        // console.log('EmailForm', email);
    }, []);

    useEffect(() => {
        if (errorMessage)
            setEmailErrorMessage(errorMessage);
    }, [errorMessage]);

    function onEmailSubmit(e) {
        e.preventDefault();
        setEmailErrorMessage('');
        const email = document.querySelector('input[type="email"]').value;

        if (DtpUtils.validateEmail(email)
            && DtpUtils.validateEmail(secondUserEmail)
            && userEmailsMatch) {
            onSubmit(email);
        }
        else if (!DtpUtils.validateEmail(email)) {
            setEmailErrorMessage('Invalid email address');
        } else if (!secondUserEmail) {
            setEmailMatchError('The provided emails do not match');
        }
    }

    const verifyEmailMatch = (mail, secondMail) => {
        if (mail === secondMail) {
            setuserEmailsMatch(true);
            setEmailMatchError('');
            setEmailErrorMessage('');
        } else {
            setEmailMatchError('The provided emails do not match');
        }
    }

    const handleEmailChange = (e) => {
        const email = e.target.value;
        console.log("Email form input: ", email);
        if (DtpUtils.validateEmail(email)) {
            setUserEmail(email);
            setEmailErrorMessage('');
        }
        if (secondUserEmail) {
            verifyEmailMatch(email, secondUserEmail);
        }
    }

    const handleVerificationEmailChange = (e) => {
        const email = e.target.value;
        setSecondUserEmail(email);
        verifyEmailMatch(email, userEmail);
    }

    return (
        <>
            {emailErrorMessage ?
                <span className={styles.errorMessage}>{emailErrorMessage}</span>
                : null}
            <form onSubmit={onEmailSubmit} className={styles.emailForm}>
                <input type="email" name="email" id="email" defaultValue={userEmail} onChange={(e) => handleEmailChange(e)} className={styles.emailInput} />
                {emailMatchError ? <p className={styles.errorMessage}>{emailMatchError}</p> : null}
                {userEmail &&
                    <>
                        <p>Please confirm your email address:</p>
                        <input type="email" name="secondEmail" id="secondEmail" className={styles.emailInput} onChange={(e) => handleVerificationEmailChange(e)} />
                    </>}
                <button type="submit" className={styles.submitButton}>{btnLabel}</button>
            </form>
        </>

    );
}

export default EmailForm;