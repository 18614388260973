import styles from '../styles/whoWeAre.module.css';
import lighting from '../assets/images/lighting.webp';
import bigReys from '../assets/images/bigReys.svg';
import hero from '../assets/images/hero.svg';
import smallreys from '../assets/images/smallreys.svg';
import hand from '../assets/images/hand.svg';
import screen from '../assets/images/screen.svg';
import pair_icon from '../assets/images/pair_icon.svg';
import device from '../assets/images/device.svg';
import globe from '../assets/images/globe.svg';
import smilePhone from '../assets/images/smilePhone.svg';
import zigzag from '../assets/images/zigzag.svg';
import mission from '../assets/images/mission.svg';
import splash from '../assets/images/splash.svg';
import values from '../assets/images/Values.svg';
import stars from '../assets/images/stars.svg';
import leftArrow from '../assets/images/leftArrow.svg';
import rightArrow from '../assets/images/rightArrow.svg';
import { Helmet } from 'react-helmet';

const WhoWeAre = () => {
    return (

        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>

            <div className={styles.pageContainer}>
                <title>Who we are - Device Tracker Plus</title>
                <div className={styles.mainContainer}>
                    <div className={styles.topSectionContainer}>
                        <img src={lighting} width={80} height={80} alt="lighting" className={styles.lighting} />
                        <div className={styles.mtRegular}>
                            <h1 className={styles.firstHeader}>Device Tracker Plus has helped over <span className={styles.hightLightText}>5 million</span> people around the world keep their phones and their loved ones <span className={styles.hightLightText}>safe</span>.</h1>
                            <p className={styles.firstDescription}>Device Tracker Plus is the world’s leading mobile tracking app. Our app is designed to help people locate lost or stolen devices, including smartphones, tablets, and laptops, using GPS technology to provide accurate location information. But we’re more than just a device tracking app. Our main goal is to help people stay safe and secure, whether that means keeping track of your lost or stolen device, or knowing the whereabouts of your loved ones.</p>
                        </div>
                        <div className={styles.firstImageContainer}>
                            <img src={bigReys} width={70} height={47} alt="reyes" className={styles.reyes} />
                            <img src={hero} alt="team_photo" className={styles.heroImage} />
                            <img src={smallreys} width={70} height={47} alt="smallreys" className={styles.smallreys} />
                        </div>

                    </div>
                    <div className={styles.vitalsContainer}>
                        <img src={hand} width={100} height={47} alt="hand" className={styles.hand} />
                        <img src={screen} width={150} height={47} alt="screen" className={styles.screen} />
                        <h1 className={styles.vitalsHeader}>Our <span className={styles.hightLightText}> Vital</span> Stats</h1>
                        <div className={styles.vitalsFeaturesContainer}>
                            <div className={styles.vitalsFeatureBox}>
                                <img src={pair_icon} width={50} height={50} alt="pairIcon" />
                                <p className={styles.vitalTitle}>Over</p>
                                <p className={styles.vitalSubTitle}>5 million</p>
                                <p className={styles.vitalDescription}>users</p>
                            </div>
                            <div className={styles.vitalsFeatureBox}>
                                <img src={device} width={50} height={50} alt="deviceIcon" />
                                <p className={styles.vitalTitle}>Over</p>
                                <p className={styles.vitalSubTitle}>10 million</p>
                                <p className={styles.vitalDescription}>devices tracked</p>
                            </div>
                            <div className={styles.vitalsFeatureBox}>
                                <img src={globe} width={50} height={50} alt="globeIcon" />
                                <p className={styles.vitalTitle}>Over</p>
                                <p className={styles.vitalSubTitle}>10 billion</p>
                                <p className={styles.vitalDescription}>location events</p>
                            </div>
                            <div className={styles.vitalsFeatureBox}>
                                <img src={smilePhone} width={125} height={50} alt="smilePhoneIcon" />
                                <p className={styles.vitalTitle}>Over</p>
                                <p className={styles.vitalSubTitle}>100 million</p>
                                <p className={styles.vitalDescription}>hours of use, <br></br> keeping phones and people safe</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.topSectionContainer}>
                        <img src={zigzag} width={47} height={47} alt="zigzag" className={styles.zigzag} />
                        <div>
                            <h1 className={styles.missionTitle}>Our <span className={styles.hightLightText}>Mission</span></h1>
                            <h4 className={styles.secondaryTitle}>We started Device Tracker Plus with the mission to use advanced technology to keep people safe and secure.</h4>
                            <br />
                            <p className={styles.firstDescription}>Our app uses GPS technology to provide instant accurate location information, making it easier for our users to track their lost or stolen devices. We offer a range of other features to help our users stay safe, including the ability to track the location of family members or friends, and set up geofencing alerts.</p>
                            <br />
                            <p className={styles.firstDescription}>Our mission is to provide the best possible location tracking solution for our users, to help keep them safe and secure. We’re constantly improving Device Tracker Plus and adding new features to make it even more useful for our users. Our team is committed to providing the best possible customer service and ensuring that our app lives up to its name as the #1 global tracking app.</p>
                        </div>
                        <div className={styles.firstImageContainer}>
                            <img src={mission} width={460} height={500} alt="mission" className={styles.heroImage} />
                        </div>

                    </div>
                    <div className={styles.topSectionContainer}>
                        <div className={styles.firstImageContainer}>
                            <img src={splash} width={70} height={47} alt="splashIcon" className={styles.splash} />
                            <img src={values} width={460} height={500} alt="hands_on_tree" className={styles.heroImage} />
                            <img src={stars} width={70} height={47} alt="stars" className={styles.stars} />
                        </div>

                        <div>
                            <h1 className={styles.missionTitle}>Our <span className={styles.hightLightText}>Values</span></h1>
                            <h4 className={styles.secondaryTitle}>At Device Tracker Plus, we value safety, security, and innovation.</h4>
                            <br />
                            <p className={styles.firstDescription}>We believe in using technology to solve real-world problems, and we’re committed to providing our customers with the best possible experience. We take our responsibility to keep our users safe very seriously, and we’re dedicated to providing exceptional customer service.</p>
                            <br />
                            <p className={styles.firstDescription}>Overall, Device Tracker Plus is an essential tool for anyone who wants to keep their devices and loved ones safe. With our powerful tracking features and commitment to constant improvement, we’re here to give you peace of mind, and we look forward to continuing to serve our users around the world.</p>
                        </div>
                    </div>
                    <div className={styles.bottomContainer}>
                        <a href="/" className='trackingButton'>Start Tracking Now</a>
                        {/* <StartTrackingButton title="Start Tracking Now" style={`trackingButton`} /> */}
                        <div className={styles.bottomIconsContainer}>
                            <img src={leftArrow} width={150} height={50} alt="leftArrow" className={styles.leftArrow} />
                            <img src={rightArrow} width={150} height={50} alt="rightArrow" className={styles.rightArrow} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WhoWeAre;