import React, { useEffect, useState } from 'react';
import styles from '../styles/welcomeFamily.module.css';
import useMediaQuery from '../hooks/useMediaQuery';
import FamilySendSmsForm from '../components/sharedComponents/FamilySmsForm';
import GroupCodePanel from '../components/sharedComponents/GroupCodePanel';
import StoreButtons from '../components/sharedComponents/StoreButtons';
import { TagManagerUtils } from '../utils/TagManagerUtils';
import { Helmet } from 'react-helmet';


const Family = () => {

    const [groupCode, setGroupCode] = useState("");
    const isAboveMediumScreens = useMediaQuery("(min-width: 750px)");
    const [deepLink, setDeepLink] = useState("");


    useEffect(() => {

        async function checkPaymentStatus() {
            const userId = localStorage.getItem('userId');
            if (userId) {
                const res = await fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + "payment/status?userId=" + userId);

                if (!res.ok) {
                    // This will activate the closest `error.js` Error Boundary
                    setGroupCode("");
                    throw new Error('Failed to fetch data')
                }

                let data = await res.json();

                if (data.deviceGroup && data.deviceGroup.code) {

                    TagManagerUtils.sendOnPaymentEventIfNotSentBefore();
                    localStorage.setItem('payment-data', JSON.stringify(data));
                    localStorage.setItem('email', data.email);
                    setGroupCode(data.deviceGroup.code);
                    if (data.deviceGroup.invitationLink) {
                        setDeepLink(data.deviceGroup.invitationLink);
                    }
                }
            }
        }

        let params = new URLSearchParams(document.location.search.substring(1));
        let code = params.get('code');
        let link = params.get('link');
        if (code) {
            setGroupCode(code);
            if (link) {
                setDeepLink(link);
            }
            TagManagerUtils.sendOnPaymentEventIfNotSentBefore();
        } else {
            checkPaymentStatus();
        }

    }, []);


    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <div className={styles.mainBgc}>
                <title>Family - Device Tracker Plus</title>
                <div className={styles.familyContainer}>
                    <div className={styles.familyHeaderContainer}>
                        <h2 className={styles.familyHeader}>Thank you for subscribing to Device Tracker Plus</h2>
                        <p className={styles.familySubtitle}><br /> This is your group code. Enter it in the app to sign into your account from any device. <br /></p>
                    </div>

                    <GroupCodePanel groupCode={groupCode} />

                    {isAboveMediumScreens &&
                        <FamilySendSmsForm />
                    }
                    <div className={styles.downloadContainer}>
                        {isAboveMediumScreens ?
                            <p className={styles.boldParagraph}>Download Device Tracker Plus from your device’s app store to finish setting up your account.</p>
                            :
                            <p className={styles.standardParagraph}>Click on the button below to install the app and start tracking.</p>
                        }

                    </div>
                    {!isAboveMediumScreens && <StoreButtons link={deepLink} />}
                </div>
            </div>
        </>


    )
}

export default Family;