import styles from '../../styles/policy.module.css';

const List = () => {

  return (
    <div className={styles.listContainer}>
      <ol className={styles.list}>
        <li>Introduction</li>
        <li>What data do we collect about you</li>
        <li>How we collect your personal data</li>
        <li>How we use your personal data</li>
        <li>Disclosures of your personal data</li>
        <li>International transfers</li>
        <li>Data security</li>
        <li>Data retention</li>
        <li>Your legal rights</li>
        <li>Third-party links</li>
        <li>Cookies</li>
        <li>Version</li>
      </ol>
    </div>
  )
}

export default List;