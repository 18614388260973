export const bottomLoactionSharingFeatures = [
    {
        id: '1',
        title: 'For Parents:',
        link: '/familyTracking',
        partContent: 'Say goodbye to the constant need for texts and check-ins, and feel confident that your teen is safe wherever they go. From school to their social life, you can give them the freedom they want, knowing you’re only a screen away with our ',
        linkContent: 'family tracking app',
        content: '.',
        imgName: 'phones'
    },
    {
        id: '2',
        title: 'For Caregivers:',
        link: '/',
        partContent: '',
        linkContent: 'A device tracker app',
        content: ' allows you to monitor their location with respect and dignity. Our geofence alerts and emergency alerts help everything go smoothly and offer reassurance in case of accidents, without the need for constant, in-person supervision.',
        imgName: 'markers'
    },
    {
        id: '3',
        title: 'For Couples: ',
        content: "Stay connected with your partner, no matter where life takes you. Whether commuting late or travelling apart, you can share your location only when needed and enjoy the balance of staying in touch, without feeling controlled.",
        imgName: 'envelope'
    },
    {
        id: '4',
        title: 'For Friends:',
        content: 'Make meet-ups and group plans effortless with real-time location sharing. Whether exploring a new city, planning a night out, or keeping tabs on your group during a trip, our app keeps everyone on the same page. Stay close, even when miles apart, and never miss a chance to connect.',
        imgName: 'scellEnvelope'
    },
];