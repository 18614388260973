import React, { useEffect, useState } from 'react';
import styles from '../styles/cancel.module.css';
import { Helmet } from 'react-helmet';


const CancelMobile = () => {

    const [storeName, setStoreName] = useState("Google Play store");
    const [storeSubscriptionUrl, setStoreSubscriptionUrl] = useState("https://play.google.com/store/account/subscriptions");

    useEffect(() => {

        let params = new URLSearchParams(document.location.search.substring(1));
        let store = params.get('store');

        //console.log("Deeplink from query param: ", link);
        if (store && store === 'apple') {
            setStoreName('Apple App store');
            setStoreSubscriptionUrl('https://apps.apple.com/account/subscriptions');
        } else {
            // Default is google
        }

    }, []);

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <div className={styles.mainContainer}>
                <div className={styles.cancelCard}>
                    <h1 className={styles.cancelHeading}>Cancel account</h1>
                    <h2 className={styles.cancelSubtitle}>It looks like you signed up for Device Tracker Plus on {storeName}. Using the device you signed up with, click the button below to go to your subscriptions page, where you can cancel your account.</h2>
                    <a href={storeSubscriptionUrl} target='blank' className={styles.submitButton}>Manage subscription</a>
                </div>
            </div>
        </>
    )
}

export default CancelMobile;