import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from '../../styles/track.module.css';
import useMediaQuery from '../../hooks/useMediaQuery';
import StartTrackingButton from './StartTrackingButton';




const FeaturesCarousel = ({ features }) => {

    const isAboveMediumScreens = useMediaQuery("(min-width: 750px)");

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 750 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 749, min: 0 },
            items: 1
        }
    };
    return (
        <div>
            <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                infinite={false}
                autoPlay={false}
                arrows={true}
                keyBoardControl={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
            >
                {features.map(({ id, title, content, imgName, link, linkContent, partContent }) => (
                    <div key={id}
                        className={styles.featureCarouselContainer}
                    >
                        <div className={styles.featureImageContainer}>
                            <img loading="lazy" src={isAboveMediumScreens ? require(`../../assets/carouselImages/${imgName}_desk.webp`) : require(`../../assets/carouselImages/${imgName}_mobile.webp`)} alt="" />
                        </div>
                        <div className={styles.featureTextContainer}>
                            <p className={styles.featureTitle}>{title}</p>
                            <p className={styles.featureContent}>{partContent}{link ? <a href={link}>{linkContent}</a> : null}{content}</p>
                            <div className={styles.buttonContainer}>
                                <StartTrackingButton title="Start Tracking Now" style={`trackingButton`} />
                            </div>
                        </div>


                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default FeaturesCarousel;