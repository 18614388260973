import React, { useState, lazy, Suspense } from 'react';
import heroImageDesk from '../../assets/images/heroHome_desk.webp';
import heroImageMobile from '../../assets/images/heroHome_mobile.webp';
import style from '../../styles/track.module.css';
import Spinner from '../sharedComponents/Spinner';

const PhoneVerificationComponent = lazy(() => import('../sharedComponents/PhoneVerification'));
const HeroImageComponent = lazy(() => import('../sharedComponents/HeroImageComponent'));

const TrackHero = () => {

    const [showSearchInput, setShearchInput] = useState(true);

    const togglePhoneVerificationPopup = (e) => {
        e.preventDefault();
        setShearchInput(!showSearchInput);
    }
    return (
        <section className="mainBgc">
            <div className="mainContainer">
                <div className="heroContainer">
                    <h1 className="heroHeading">Track the location of <span className="hightLightText">any</span>
                        <br></br> phone for complete <span className="hightLightText">peace</span><br></br> of mind</h1>
                    <p className={style.trackSubtitle}>Our mobile number tracker lets you track the location of any phone easily, so you can stay connected with family, friends, and those who matter most. </p>
                    <Suspense fallback={<Spinner />}>
                        {showSearchInput && <PhoneVerificationComponent showPopUp={togglePhoneVerificationPopup} />}
                    </Suspense>
                </div>
                <Suspense fallback={<Spinner />}>
                    <HeroImageComponent desktopImage={heroImageDesk} mobileImage={heroImageMobile} />
                </Suspense>
            </div>
        </section>
    );
}

export default TrackHero;