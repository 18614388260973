import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './Global.css';
import NavBar from './components/navigations/NavBar';
import FooterNav from './components/navigations/FooterNav';
import Home from './pages/Home';
import Track from './pages/Track';
import WhoWeAre from './pages/WhoWeAre';
import FAQ from './pages/FAQ';
import Help from './pages/Help';
import CancelAccount from './pages/CancelAccount';
import GroupCode from './pages/GroupCode';
import Welcome from './pages/Welcome';
import Family from './pages/Family';
import Terms from './pages/Terms';
import Policy from './pages/PrivacyPolicy';
import AccountCanceled from './pages/AccountCanceled';
import CancelConfirmPage from './pages/CancelConfirmPage';
import CancelMobile from './pages/CancelMobile';
import CancelNotFound from './pages/CancelNotFound';
import CancelRequested from './pages/CancelRequested';
import NotFound from './pages/404';
import FamilyTracking from './pages/FamilyTracking';
import EmergencyAlertCampaign from './pages/EmergencyAlertCampaign';
import LocationSharingCampaign from './pages/LocationSharingCampaign';


function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/track" element={<Track />} />
            <Route path="/whoWeAre" element={<WhoWeAre />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/help" element={<Help />} />
            <Route path="/cancelAccount" element={<CancelAccount />} />
            <Route path="/groupCode" element={<GroupCode />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/family" element={<Family />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/cancelAllreadyCanceled" element={<AccountCanceled />} />
            <Route path="/cancelConfirm" element={<CancelConfirmPage />} />
            <Route path="/cancelMobile" element={<CancelMobile />} />
            <Route path="/cancelNotFound" element={<CancelNotFound />} />
            <Route path="/cancelRequested" element={<CancelRequested />} />
            <Route path="/familyTracking" element={<FamilyTracking />} />
            <Route path="/emergencyAlert" element={<EmergencyAlertCampaign />} />
            <Route path="/locationSharing" element={<LocationSharingCampaign />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <FooterNav />
      </div>
    </Router>
  );
}

export default App;
