export const contactFormTopic = [
    {
        id: 1,
        content: 'I have a general question about the service'
    },
    {
        id: 2,
        content: 'I need help using the service'
    },
    {
        id: 3,
        content: 'I’m having a technical issue'
    },
    {
        id: 4,
        content: 'I’d like to cancel my account'
    },
    {
        id: 5,
        content: 'I have a question about my subscription'
    },
];