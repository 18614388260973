import styles from '../../styles/help.module.css';
import helpicon from '../../assets/images/help-icon.webp';
import maintenance from '../../assets/images/maintenance.webp';
import cancel from '../../assets/images/cancel_icon.webp';
import darkChevron from '../../assets/images/darkChevron.webp';

const HelpButtons = () => {

    const handleClick = () => {
        const contactSection = document.getElementById("contact-form");
        contactSection?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className={styles.buttonsContainer}>
            <a href="/faqs/" className={styles.helpButton}>
                <div>
                    <img src={helpicon} width={32} height={54} alt="help_icon"style={{marginRight: '30%', paddingTop: '5px'}} /><p>FAQs</p>
                </div>
            </a>
            <a href="/groupCode/" className={styles.helpButton}>
                <div >
                    <img src={darkChevron} width={32} height={54} alt="lightChevron_icon" style={{marginRight: '30%'}} /><p>Forgot group code</p>
                </div>
            </a>
            <button onClick={handleClick} className={styles.helpButton} >
                <div>
                    <img src={maintenance} width={50} height={54} alt="maintenance_icon" style={{marginRight: '25%'}}/><p>Contact us</p>
                </div>
            </button>
            <a href="/cancelAccount/" className={styles.helpButton}>
                <div>
                    <img src={cancel} width={50} height={54} alt="cancel_icon" style={{marginRight: '25%'}} /><p>Cancel account</p>
                </div>
            </a>
        </div >
    );
}

export default HelpButtons;