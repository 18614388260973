import { TagManagerUtils } from '../../utils/TagManagerUtils';
import React, { useEffect, useState } from 'react';
import { appInsights } from '../azureAppInsights/azureAppInsights';
import { useNavigate } from "react-router-dom"

const WelcomeLogic = () => {
    const [timeout, setTimeout] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {

        async function checkPaymentStatus() {
            const res = await fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + "payment/status?userId=" + localStorage.getItem('userId'));
            if (!res.ok) {
                // This will activate the closest `error.js` Error Boundary
                appInsights.trackTrace({ message: 'WELCOMELOGIC: Failed to fetch payment data' });
                throw new Error('Failed to fetch payment data')
            }

            let data = await res.json();
            if (data.isPaid) {
                // need to set email here
                localStorage.setItem('email', data.email);
                localStorage.setItem('payment-data', JSON.stringify(data));
                console.log('payment-data', JSON.stringify(data));
                appInsights.trackTrace({ message: 'WELCOMELOGIC: Payment data fetched', properties: data });

                if (interval)
                    window.clearInterval(interval);

                await sendOnPaymentEventAndRedirectToFamily(data.deviceGroup.code, data.deviceGroup.invitationLink);
            }
        }

        async function sendOnPaymentEventAndRedirectToFamily(code, link) {

            if (!window.dataLayer) {
                console.log('AHTUNG! window.dataLayer is not defined! (On dev tag is disabled in src/app/laytout.tsx)');
                appInsights.trackTrace({ message: 'WELCOMELOGIC: window.dataLayer is not defined! (On dev tag is disabled in src/app/laytout.tsx)' });
            }
    
            if (localStorage.getItem('gtmOnPaymentSent') === 'YES') {
                console.log('gtmOnPaymentSent', 'Already sent, redirecting to family', code);
                appInsights.trackTrace({ message: 'WELCOMELOGIC: gtmOnPaymentSent, Already sent, redirecting to family', properties: { code: code } });
    
                navigate("/family?code=" + code + "&link=" + link);
                return;
            }
    
            window.dataLayer = window.dataLayer || [];
    
            let onPaymentData = {
                event: 'onPayment',
                amountPaid: localStorage.getItem('amountPaid'),
                paymentId: localStorage.getItem('userId'),
                currency: localStorage.getItem('currency'),
                email: localStorage.getItem('email'),
                ecommerce: TagManagerUtils.buildEcommerceObject(),
                eventCallback: (id) =>  { 
                    localStorage.setItem('gtmOnPaymentSent', 'YES');
                    console.log('onPayment event sent to GTM', onPaymentData);
                    appInsights.trackTrace({ message: 'WELCOMELOGIC: onPayment event sent to GTM', properties: onPaymentData });
    
                    if (code) {
                        const failyPageWithParamsUrl = "/family?code=" + code + "&link=" + link;
                        console.log('onPayment eventCallback: redirecting to', failyPageWithParamsUrl);
                        appInsights.trackTrace({ message: 'WELCOMELOGIC: onPayment eventCallback: redirecting to family with code', properties: { url: failyPageWithParamsUrl } });
                        navigate(failyPageWithParamsUrl)
                    } else {
                        console.log('onPayment eventCallback: NO CODE, redirecting to /family');
                        appInsights.trackTrace({ message: 'WELCOMELOGIC: onPayment eventCallback: NO CODE, redirecting to /family without code' });
                        navigate('/family')
                    }
                 },
                eventTimeout: 2000
            };
    
            window.dataLayer.push(onPaymentData);
        }

        const maxTimeout = 40;
        const interval = setInterval(() => {

            setTimeout(timeout + 1);
            console.log('Checking payment status', timeout, 'out of', maxTimeout);
            if (timeout > maxTimeout) // waits 40 * 3sec = 2 minutes
            {
                appInsights.trackTrace({ message: 'WELCOMELOGIC: Payment status check timeout' });
                const paymentStr = localStorage.getItem('payment-data') || '{}';
                var paymentData = JSON.parse(paymentStr);
                if (paymentData?.isPaid) {
                    // probably paiment succeeded but tag manager failed
                    window.clearInterval(interval);
                    navigate("/family");
                }
                else {
                    // probably paiment failed
                    appInsights.trackTrace({ message: 'WELCOMELOGIC: Payment failed?' });
                    localStorage.removeItem('payment-data');
                    navigate("/");
                }
            }

            checkPaymentStatus();


        }, 3000); // 4 seconds to let tag manager send onPayment event
        return () => clearInterval(interval);
    }, [timeout]);

    return (
        <>
            <div></div>
        </>
    );
}

export default WelcomeLogic;