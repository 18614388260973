export const bottomEmergencyAlertFeatures = [
    {
        id: '1',
        title: 'For Parents:',
        link: '/familyTracking',
        partContent: 'With the Device Tracker Plus emergency button app, parents have peace of mind knowing their teens are safe while enjoying independence. From checking in after school to ensuring safe travels, parents can rely on ',
        linkContent: 'our family tracking app',
        content: ' without needing constant texts or calls.',
        imgName: 'phones'
    },
    {
        id: '2',
        title: 'For Caregivers:',
        content: 'For those caring for vulnerable relatives or loved ones, using Device Tracker Plus as an emergency alert app provides an invaluable safety net, without intruding on their lives. If a loved one leaves a designated area or has an emergency, you’re alerted instantly, so you can respond quickly.',
        imgName: 'markers'
    },
    {
        id: '3',
        title: 'For Couples: ',
        content: "Whether your partner is walking home late at night, commuting, or travelling solo, the SOS emergency button ensures they can instantly alert you in case of danger. Maintain a sense of security without feeling intrusive, knowing help is just a button press away.",
        imgName: 'envelope'
    },
    {
        id: '4',
        title: 'For Friends:',
        content: 'Stay safe and look out for each other during group outings, adventures, or nights out. With the panic button feature, friends can quickly send an emergency alert to notify the group if they need help.',
        imgName: 'scellEnvelope'
    },
];