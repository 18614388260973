import React, { useState } from 'react';
import styles from '../styles/cancel.module.css';
import EmailForm from '../components/sharedComponents/EmailForm';
import Spinner from '../components/sharedComponents/Spinner';
import { Helmet } from 'react-helmet';

const CancelAccount = () => {

    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(true);

    async function cancelAccount(email) {
        setIsLoading(true);
        const res = await fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + "cancel/request",
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email }),
            });
        if (res.ok) {
            setIsSuccess(true);
            window.location.href = "/cancelRequested";
        } else {
            let data = await res.json();
            switch (data.error) {
                case "ACCOUNT_NOT_FOUND": {
                    window.location.href = "/cancelNotFound";
                    break;
                }
                case "ACCOUNT_ALREADY_CANCELED": {
                    window.location.href = "/cancelAllreadyCanceled";
                    break;
                }
                case "INVALID_EMAIL": {
                    setEmailErrorMessage("Invalid email address.");
                    break;
                }
                case "CONTACT_SUPPORT": {
                    setEmailErrorMessage("Something went wrong please contact support.");
                    break;
                }
                case "SUBSCRIBED_WITH_APPLE": {
                    window.location.href = "/cancelMobile?store=apple";
                    break;
                }
                case "SUBSCRIBED_WITH_GOOGLE": {
                    window.location.href = "/cancelMobile";
                    break;
                }
                default: {
                    setEmailErrorMessage(data.error);
                    break;
                }
            }
        }

        setIsLoading(false);
        console.log("cancelAccount: ", emailErrorMessage); //type of error: 1. canceled or not found
    }

    function onEmailSubmit(email) {
        cancelAccount(email);
    }


    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <title>Cancel - Device Tracker Plus</title>
            <div className={styles.mainContainer}>
                <div className={styles.cancelCard}>
                    <h1 className={styles.cancelHeading}>Cancel account</h1>
                    <h2 className={styles.cancelSubtitle}>Please enter the email address you used to create your Device Tracker Plus account:</h2>
                    {emailErrorMessage && <span style={{ color: 'red' }}>{emailErrorMessage}</span>}
                    <EmailForm btnLabel="Cancel my account" onSubmit={onEmailSubmit} />
                </div>


                {isLoading ?
                    <div className={styles.cancelSpinnerContainer}>
                        <Spinner />
                    </div>
                    : null}
            </div>
        </>
    );
}

export default CancelAccount;