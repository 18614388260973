export const emergencyAlertFeatureList = [
    {
        id: '1',
        title: 'Panic Button for Instant Alerts',
        content: 'In case of emergencies, our panic button app allows you to send an instant alert to your contacts at the touch of a button. With the Device Tracker Plus panic alarm app on your iPhone or Android, help is always within reach.',
        imgName: 'alertCarousel'
    },
    {
        id: '2',
        title: 'Live Location Sharing',
        link: '/locationSharing',
        partContent: 'Device Tracker Plus also offers real-time location sharing, so you can see exactly where your loved ones are when they need help. ',
        linkContent: 'A location sharing app',
        content: ' is the ultimate tool to keep your group safe and connected.',
        imgName: 'locationHistoryCarousel'
    },
    {
        id: '3',
        title: 'Geo Fence Alerts',
        content: "Set up boundaries around key locations, like home, school and work, and get notifications when someone leaves or enters these areas. This feature provides an added layer of security, particularly for parents and caregivers.",
        imgName: 'familyCarousel'
    },
    {
        id: '4',
        title: 'Instant Messaging',
        link: '/track',
        partContent: '',
        linkContent: "Track a phone's location",
        content: " and communicate instantly, all in one app. Device Tracker Plus is the ultimate tool to keep your family safe and connected. Send messages, photos, or updates to loved ones directly from the app.",
        imgName: 'messegesCarousel'
    },
];