export const bottomFamlilyTrackingFeatures = [
    {
        id: '1',
        title: 'Intuitive and Easy to Use ',
        content: 'Our app is designed to be user-friendly, even for those who aren’t tech-savvy.',
        imgName: 'phones'
    },
    {
        id: '2',
        title: 'Clear, Real-Time Updates',
        content: 'Accurate live location data lets you check on your family without constant calls or texts.',
        imgName: 'markers'
    },
    {
        id: '3',
        title: 'Mutual Phone Tracking ',
        content: "Parents tracking children also share their own location, and our privacy mode lets users temporarily hide their location from the group.",
        imgName: 'envelope'
    },
    {
        id: '4',
        title: 'Cross Platform Tracking',
        link: '/',
        partContent: 'It doesn’t matter if you need to track your child’s iPhone location with an Android phone, our ',
        linkContent: 'device tracker app',
        content: ' works on all major platforms.',
        imgName: 'scellEnvelope'
    },
];