import React from 'react';
import styles from '../styles/welcomeFamily.module.css';
import WelcomeLogic from '../components/sharedComponents/WelcomeLogic';
import Spinner from '../components/sharedComponents/Spinner';
import { Helmet } from 'react-helmet';


const Welcome = () => {
    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <div className={styles.mainBgc}>
                <title>Welcome - Device Tracker Plus</title>
                <div className={styles.mainContainer}>
                    <h2 className={styles.welcomeHeading}>Please wait...</h2>
                    <h4 className={styles.welcomeSecondHeading}>Confirming your payment...</h4>
                    <Spinner />
                    <WelcomeLogic />
                </div>
            </div>
        </>

    );
}

export default Welcome;