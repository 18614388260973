export const featurelist_Std = [
    {
        id: '1',
        title: 'Location Sharing',
        link: '/locationSharing',
        partContent: 'Keep an eye on your loved ones while preserving their independence with',
        linkContent: ' location sharing.',
        content: ' Stay connected with your family, so you always have peace of mind knowing they’re safe.',
        imgName: 'locationHistoryCarousel'
    },
    {
        id: '2',
        title: 'Emergency Alert',
        link: '/emergencyAlert',
        linkContent: 'emergency alert',
        partContent: 'Alert your emergency contacts when you need help at the push of a button with our SOS ',
        content: ' feature.',
        imgName: 'alertCarousel'
    },
    {
        id: '3',
        title: 'Place Alerts',
        content: 'Know when your kids get to school safely or when your partner leaves work, without having to ask, with instant location updates.',
        imgName: 'familyCarousel'
    },
    {
        id: '4',
        title: 'Multi Device Tracking',
        content: 'Keep your data and devices safe with live tracking for up to 5 devices. Quickly locate your devices if lost or stolen.',
        imgName: 'multiDeviceCarousel'
    },
];