import React from 'react';
import styles from '../styles/cancel.module.css';
import { Helmet } from 'react-helmet';


const CancelNotFound = () => {

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <div className={styles.mainContainer}>
                <div className={styles.cancelCard}>
                    <h1 className={styles.cancelHeading}>Cancel account</h1>
                    <div style={{ color: '#F15223', textAlign: 'center' }}>
                        <h2 className={styles.cancelSubtitle}>We couldn’t locate an account linked to that email address.</h2>
                        <h2 className={styles.cancelSubtitle}>An agent has been notified and will locate your account and come back to you within 24 hours to confirm.</h2>
                        <a href="/cancelAccount" className={styles.buttonContainer}><button className={styles.submitButton}>Go back to cancel account page</button></a>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CancelNotFound;