import styles from '../styles/404.module.css'
import directionImage from '../assets/images/travelImg.webp';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    const [pathName, setPathName] = useState('');

    useEffect(() => {
        setPathName(window.location.pathname);
    }, [])

    return (
        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <section className={styles.mainBgc}>
                <div className={styles.mainContainer}>
                    <h1 className={styles.heroHeading}>404 - Page {pathName} not found</h1>
                    <h2 className={styles.smallHeading}>It looks like this page is lost. If you lose things a lot, it might be time to try Device Tracker Plus - a great way to make sure you can find your phone the next time it goes missing.</h2>
                    <h3 className={styles.smallHeading}>Use one of these links to get back on track:</h3>
                    <div className={styles.gridContainer}>
                        <ul style={{ fontWeight: '600', listStyle: 'none' }}>
                            <li>
                                <a href={'/'}>Home</a>
                                <p>: Everything you need to know about our app and how to subscribe.</p>
                            </li>
                            <li>
                                <a href={'/whoWeAre'}>Who We Are</a>
                                <p>: Learn about our mission to help the world stay safe and connected.</p>
                            </li>
                            <li>
                                <a href={'/faqs/'}>FAQs</a>
                                <p>: Got any questions about the app? Find the answers here.</p>
                            </li>
                            <li>
                                <a href={'/help/'}>Help</a>
                                <p>: If you can't find the answer in FAQs, our team is here to help.</p>
                            </li>
                            <li>
                                <a href={'/terms/'}>Terms</a>
                                <p >: Important information on how Device Tracker Plus works and our terms of service</p>
                            </li>
                            <li>
                                <a href={'/policy/'}>Privacy Policy</a>
                                <p>: Explains how we handle data responsibly.</p>
                            </li>
                            <li>
                                <a href={'https://blog.devicetrackerplus.com'} target='blank'>Blog</a>
                                <p>: Helpful tips, guides and news about Device Tracker Plus and lots more.</p>
                            </li>
                        </ul>
                        <div>
                            <img src={directionImage} alt="direction_image" className={styles.directionImage} />
                        </div>
                    </div>
                </div>

            </section>
        </>

    )
}

export default NotFound;