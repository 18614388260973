const StartTrackingButton = ({style, title}) => {

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }

    return ( 
        <>
        <button
            id="startTracking"
            className={style}
            onClick={scrollToTop}
        >
            {title}
        </button>
    </>
     );
}
 
export default StartTrackingButton;