import style from '../../styles/track.module.css';
import rays_feature from '../../assets/images/rays_feature.webp';
import FeaturesCarousel from '../sharedComponents/FeaturesCarousel';
import { trackFeatureList } from '../../data/trackFeatureList';

const TrackFeatures = () => {
    return (
        <section className={style.main}>
            <div className={style.featuremainContainer}>
                <h1 className={style.trackFeatureHeading}>Keep Track of Your Loved Ones and Devices with Accurate Mobile Number Tracking</h1>
                <img src={rays_feature} width={52} height={79} alt="rays_image" className="mobileDisabled" />
            </div>
            <FeaturesCarousel features={trackFeatureList} />
        </section>
    );
}

export default TrackFeatures;