export const loactionSharingTestimonials =[
    {
        id: 1,
        title: "Worry-free parenting",
        content: "I can finally stop worrying about my teenager’s safety when they’re out. The geofence and emergency alert features are lifesavers for peace of mind.",
        author: "Claire, Belfast"
    },
    {
        id: 2,
        title: "Peace of mind for the family",
        content: "Device Tracker Plus has made it so much easier for my siblings and me to keep track of our elderly mum without overwhelming her. We’re all connected, and it’s a huge relief.",
        author: "Mark, Philadelphia"
    },
    {
        id: 3,
        title: "Safer while apart",
        content: "My partner and I love using the location-sharing feature when we’re travelling. It makes us feel closer and safer, especially when one of us is out late.",
        author: "Sandy, Cincinnati"
    },
    {
        id: 4,
        title: "Tracks perfectly",
        content: "Device Tracker Plus is really easy to use. I have my wife and my kids’ mobiles registered so I can always see where they are. It helps me relax, knowing they’re where they should be.",
        author: "Paul, London"
    },
    {
        id: 5,
        title: "Sets my mind at ease",
        content: "Apparently over 100,000 kids are reported missing in the UK every year. I don’t want to know how that feels! So I track my whole family with Device Tracker Plus. The tracking is easy and accurate and it gives me complete peace of mind.",
        author: "Gavin, Cardiff"
    },
    {
        id: 6,
        title: "Essential for anyone who worries",
        content: "I’ll admit I’m a worrier and I do really worry about my kids. Device Tracker helps me feel more relaxed when they’re out and about. I can’t keep them in all the time. This helps me let go a bit.",
        author: "Heather, Edinburgh"
    },
    {
        id: 7,
        title: "Makes my life easier",
        content: "Using the app I can quickly check and see where everyone is. So I don’t need to find out where they are, I can just check the app. It makes my life a hell of a lot easier!",
        author: "Olivia, Birmingham"
    },
    {
        id: 8,
        title: "The perfect solution",
        content: "Great app, works well. Installed on my phone and my tablet and it managed to locate both easily. I will be installing on my elderly father's mobile too, in order to see that he is safe at home. ",
        author: "Mark, Leeds"
    },
    {
        id: 9,
        title: 'A friend of all family',
        content: "What a lovely app, really user friendly. Love being able to keep track on my children and make sure they are safe.",
        author: "Anne, Norwich"
    },
];