import styles from '../styles/policy.module.css';
import List from '../components/policy/List';
import Introduction from '../components/policy/Introduction';
import ContactDetails from '../components/policy/ContactDetails';
import DataCollecting from '../components/policy/DataCollecting';
import ProcessingData from '../components/policy/ProcessingData';
import Regulations from '../components/policy/Regulations';
import Cookie from '../components/policy/Cookie';
import { Helmet } from 'react-helmet';


const Policy = () => {

    return (

        <>
            <Helmet>
                <title>Device Tracker App, Get Peace of Mind with Device Tracker Plus</title>
                <meta name="description" content="Accurate device tracking & real-time location sharing for families, friends & couples. Stay connected with the world's no.1 mobile device tracker app." />
            </Helmet>
            <div className={styles.mainContainer}>
                <title>Privacy Policy - Device Tracker Plus</title>
                <div className={styles.fullInnerCard}>
                    <h1 className={styles.pageTitle}>Device Tracker Plus Privacy & Cookie Policy</h1>
                    <h4 className={styles.pageSubtitle}>Effective as of September 5th 2022</h4>
                    <List />
                    <Introduction />
                    <ContactDetails />
                    <DataCollecting />
                    <ProcessingData />
                    <br />
                    <Regulations />
                    <Cookie />
                    <h4 className={styles.sectionTitleOrange}>Version</h4>
                    <p className={styles.sectionSubtitle}>This policy was updated on July 25th 2023</p>
                </div>
            </div>
        </>

    )
}

export default Policy;