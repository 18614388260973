import style from '../../styles/track.module.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const TestimonialCarousel = ({ testimonialsData }) => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 750 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 749, min: 0 },
            items: 1
        }
    };

    return (
        <div>
            <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
                {testimonialsData.map((testimonial) => (
                    <div className={style.testimonialContainer} key={testimonial.id}>
                        <p className="paragraph">{testimonial.title}</p>
                        <br></br>
                        <br></br>
                        <div>
                            ⭐⭐⭐⭐⭐
                        </div>
                        <br></br>
                        <p className="smallParagraph">&quot;{testimonial.content}&quot;</p>
                        <br></br>
                        <br></br>
                        <p className="smallParagraph hightLightText">{testimonial.author}</p>
                    </div>
                ))}
            </Carousel>
        </div>
    );
}

export default TestimonialCarousel;