import style from '../../styles/track.module.css';
import StartTrackingButton from '../sharedComponents/StartTrackingButton';
import map from '../../assets/images/map-1.webp';
import phones from '../../assets/images/phones.webp';
import greypath from '../../assets/images/greyPath.webp';
import markers from '../../assets/images/markers.webp';
import envelope from '../../assets/images/envelope.webp';
import scellenvelope from '../../assets/images/scellEnvelope.webp';

const BottomSection = () => {

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }


    return (
        <section className="bottomContainer">
            <div className={style.bottomHeaderContainer}>
                <img src={map} width={76} height={76} alt="map_image" />
                <h1 className="secondaryHeading fontWhite lgVerticalMargin">Device Tracker Plus comes with a range of great features that deliver complete <span className="hightLightText">peace of mind</span>:</h1>
            </div>
            <div className="bottomGrid">
                <div className="border">
                    <div onClick={scrollToTop} className="bottomFeature">
                        <img src={phones} width={65} height={48} alt='featureIcon' />
                        <p className='bottomFeatureParagraph text-white'>Track the location of any registered device – child’s, husband’s, wife’s – so you’ll always know where they are and if they’re safe.</p>
                        <img src={greypath} width={32} height={54} alt='greyChevron' />
                    </div>
                </div>

                <div onClick={scrollToTop} className="bottomFeature">
                    <img src={markers} width={65} height={48} alt='featureIcon' />
                    <p className='bottomFeatureParagraph text-white'>See a full location and route history plotted on a map including stop offs and timings. They’ll never be able to deny where they’ve been again.</p>
                    <img src={greypath} width={32} height={54} alt='greyChevron' />
                </div>


                <div onClick={scrollToTop} className="bottomFeature">
                    <img src={envelope} width={65} height={48} alt='featureIcon' />
                    <p className='bottomFeatureParagraph text-white'>As a Device Tracker user, if you lose your phone, you’ll be able to track it instantly. Just access your account on a different device and hit ‘Locate now’.</p>
                    <img src={greypath} width={32} height={54} alt='greyChevron' />
                </div>
                <div onClick={scrollToTop} className="bottomFeature">
                    <img src={scellenvelope} width={65} height={48} alt='featureIcon' />
                    <p className='bottomFeatureParagraph text-white'>Installs onto existing mobile devices. So there’s no need to buy additional tracking hardware – or make anyone carry it.</p>
                    <img src={greypath} width={32} height={54} alt='greyChevron' />
                </div>
            </div>
            <div className="buttonContainer">
                <StartTrackingButton style={`regTrackingButton`} title="Start Tracking Now" />
            </div>

        </section>
    );
}

export default BottomSection;