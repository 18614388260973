export const loactionSharingFeatureList = [
    {
        id: '1',
        title: 'Real-Time Location Sharing',
        content: 'Our live location sharing app provides real-time tracking for your friends, family, or loved ones. No more guessing or texting for updates, just open the app to see exactly where they are. Track them on the move with live speed data or check if they’ve reached their destination safely.',
        imgName: 'multiDeviceCarousel'
    },
    {
        id: '2',
        title: '30-Day Location History',
        content: 'You can track the routes and locations your loved ones and their devices took for the past month, so you can stay involved, even when you’re apart. GPS tracking is perfect for maintaining everyone’s independence, without sacrificing peace of mind.',
        imgName: 'locationHistoryCarousel'
    },
    {
        id: '3',
        title: 'Emergency Alerts',
        link: '/emergencyAlert',
        partContent: 'In an emergency, users can send an instant ',
        linkContent: "emergency alert",
        content: ' to the rest of the group with a single tap. The rest of the group can then track their live location and take action. It’s an essential tool for parents, caregivers, and friends who want an extra layer of safety.',
        imgName: 'alertCarousel'
    },
    {
        id: '4',
        title: 'Geofence Alerts',
        content: "Set up custom boundaries around key locations like school, home, or work. Receive notifications when your loved one arrives or leaves a specific area, so you know exactly where they are and when they’ve arrived safely.",
        imgName: 'familyCarousel'
    },
    {
        id: '5',
        title: 'Instant Messaging',
        link: '/track',
        partContent: '',
        linkContent: "Track a phone's location",
        content: " and communicate instantly, all in one app. Device Tracker Plus is the ultimate tool to keep your family safe and connected. Send messages, photos, or updates to loved ones directly from the app.",
        imgName: 'messegesCarousel'
    },
];