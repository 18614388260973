import React, { useEffect, useState } from 'react';
import styles from '../../styles/cancel.module.css';


const CancelConfirm = () => {

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        function cancelAccount(userId) {

            fetch(process.env.REACT_APP_PUBLIC_PAYMENT_URL + "cancel/confirm",
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId: userId }),
                }
            )
                .then(response => {
                    if (response.ok) {
                        //window.location.replace("/goodbye");
                    } else {
                        response.json().then(data => {
                            console.log('data', data);
                            if (data.error) {
                                setErrorMessage(data.error);
                            }
                        });
                    }
                }).catch((err) => {
                    console.log('err', err);
                    setErrorMessage('Something went wrong. Please contact support.');
                });
        }

        let params = new URLSearchParams(document.location.search.substring(1));
        var userId = params.get('userId');
        if (userId) {
            cancelAccount(userId);
        } else {
            setErrorMessage('Something went wrong. Please contact support.');
        }
    }, []);

    return (
        <>
            <div className={styles.errorMessageContainer}>
                {!errorMessage &&
                    <div className={styles.errorMessage}>{errorMessage}</div>
                }
            </div>

        </>

    )
}

export default CancelConfirm