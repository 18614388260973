import { Helmet } from 'react-helmet';
import EmergencyAlertHero from '../components/campaings/EmergencyAlertHero';
import CampaignFeatures from '../components/campaings/CampaignFeatures';
import { emergencyAlertFeatureList } from '../data/emergencyAlertFeatureList';
import EmergencyAlertHowItWorks from '../components/campaings/EmergencyAlertHowItWorks';
import CampaignBottomSection from '../components/campaings/CampaignBottomSection';
import { bottomEmergencyAlertFeatures } from '../data/bottomEmergencyAlertFeatures';
import Testimonials from '../components/track/Testimonials';
import { emergencyAlertTestimonials } from '../data/emergencyAlertTestimonials';

const EmergencyAlertCampaign = () => {
    return (
        <>
            <Helmet>
                <title>Emergency Alert App with Panic Button & SOS Alert</title>
                <meta name="description" content="Our SOS emergency alert app alerts your emergency contacts when you need help at the push of a button. Get peace of mind with Device Tracker Plus." />
            </Helmet>
            <EmergencyAlertHero />
            <CampaignFeatures sectionTitle="Stay Safe With A One-Tap Panic Button App" features={emergencyAlertFeatureList} />
            <EmergencyAlertHowItWorks />
            <CampaignBottomSection sectionHeading="The Emergency Alert App For Everyone" features={bottomEmergencyAlertFeatures} />
            <Testimonials testimonialsData={emergencyAlertTestimonials}/>
        </>
    );
}

export default EmergencyAlertCampaign;