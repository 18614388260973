export const trackFeatureList = [
    {
        id: '1',
        title: 'Real-Time Location Sharing',
        link: '/locationSharing',
        partContent: 'Our live ',
        linkContent: 'location sharing app',
        content: ' provides real-time tracking for your friends, family, or loved ones. No more guessing or texting for updates, just open the app to see exactly where they are. Track them on the move with live speed data or check if they’ve reached their destination safely.',
        imgName: 'multiDeviceCarousel'
    },
    {
        id: '2',
        title: '30-Day Location History',
        link: '/familyTracking',
        partContent: 'With our accurate ',
        linkContent: 'family tracking app',
        content: ', you can track the routes and locations your loved ones and their devices took for the past month. If a phone gets lost or stolen, you can easily see exactly where it is and where it has been from any other device in your group.  ',
        imgName: 'locationHistoryCarousel'
    },
    {
        id: '3',
        title: 'Geofence Alerts',
        content: "Set up custom geofences around important locations like your child's school, an elderly parent’s home, or your partner's workplace. Receive notifications when they arrive or leave these zones, so you’re always in the loop and reassured that they’re safe. ",
        imgName: 'familyCarousel'
    },
    {
        id: '4',
        title: 'Track Multiple Devices',
        link: '/',
        partContent: 'Our',
        linkContent: ' device tracker app',
        content: ' makes it simple to track multiple devices from a single account. If you’re looking to keep track of family members, such as children or elderly relatives, our cross-platform support means everyone you care about is just a click away, whether they’re on iOS or Android',
        imgName: 'homeCarousel'
    },
    {
        id: '5',
        title: 'Panic Button',
        link: '/emergencyAlert',
        linkContent: 'Our emergency alert',
        partContent: 'In an emergency, every second counts. ',
        content: ' feature allows users to send an instant alert to trusted contacts when they need urgent assistance. With just a tap, your family or friends can be informed of your situation and respond quickly.  ',
        imgName: 'alertCarousel'
    },
    {
        id: '6',
        title: 'Instant Messaging',
        content: "Track a phone's location and communicate instantly, all in one app. Device Tracker Plus is the ultimate tool to keep your group safe and connected. Send messages, photos, or updates to loved ones directly from the app. ",
        imgName: 'messegesCarousel'
    },
];