import styles from '../../styles/nav.module.css';
import logo from '../../assets/images/logo.webp';
import facebook from '../../assets/images/facebook.webp';
import instagram from '../../assets/images/instagram.webp';


const FooterNav = () => {

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <nav>
      <div className={styles.footerNavContainer}>
        <div onClick={scrollToTop}>
          <img src={logo} width={130} height={50} alt="logo" className={styles.logo} />
        </div>

        <div className={styles.midFooterContainer}>
          <ul className={styles.footerNavList}>
            <li>
              <a className={styles.link} href="/terms">Terms</a>
            </li>
            <li >
              <a className={styles.link} href={'/policy'}>Privacy Policy</a>
            </li>
            <li >
              <a className={styles.link} href={'/whoWeAre'}>About</a>
            </li>
            <li >
              <a className={styles.link} href={'/help'}>Contact</a>
            </li>
            <li >
              <a className={styles.link} href={'/faqs'}>FAQ's</a>
            </li>
            <li >
              <a className={styles.link} href="https://blog.devicetrackerplus.com/">Blog</a>
            </li>
          </ul>
        </div>
        <div className={styles.socialMedia}>
          <a href="https://www.facebook.com/people/Device-Tracker-Plus/100065657734292/" target='blank'><img src={facebook} alt="facebook_logo" /></a>
          <a href="https://www.instagram.com/devicetrackerplus/" target='blank'><img src={instagram} alt="instagram_logo" /></a>
        </div>
      </div>
    </nav>
  );
}

export default FooterNav;