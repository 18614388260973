import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

const defaultBrowserHistory = {
  url: "/",
  location: { pathname: "", state: { url: "" } },
  listen: () => { }
};

let browserHistory = defaultBrowserHistory;
if (typeof window !== "undefined") {
  browserHistory = { ...browserHistory, ...window.history };
  browserHistory.location.pathname = browserHistory?.location?.state?.url;
}

// *** Add the Click Analytics plug-in. ***
var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
  autoCapture: true
};

let reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_PUBLIC_APP_INSIGHT,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxPerfTracking: true,
    samplingPercentage: 100,
    isBrowserLinkTrackingEnabled: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig
    }
  },
});

appInsights.loadAppInsights();
export { appInsights, reactPlugin };