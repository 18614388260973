import React, { useState } from 'react';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid';
import styles  from '../../styles/welcomeFamily.module.css';


const GroupCodePanel = ({groupCode}) => {

    const [isCopied, setIsCopied] = useState(false);

    function copyToClipBoard() {
        navigator.clipboard.writeText(groupCode);
        setIsCopied(true);
    }


    return ( 
        <>
        <div className={styles.groupCodePanelContainer}>
            <div className={styles.codePanel}>
                <div id="family-code" className={styles.groupCode}>{groupCode}</div>
            </div>
            <div className={styles.copyIconContainer}>
                {!isCopied && <ClipboardDocumentListIcon className={styles.copyIcon} onClick={copyToClipBoard} />}
                {isCopied ? <p className={styles.copy}>Copied!</p> : <p className={styles.copy}>Copy</p>}
            </div>

        </div>
    </>
     );
}
 
export default GroupCodePanel;