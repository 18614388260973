import styles from '../../styles/policy.module.css';

const DataCollecting = () => {
    return (
        <div>
            <h4 className={styles.sectionTitleOrange}>How we collect your personal data</h4>
            <p className={styles.sectionSubtitle}>Personal data means any information capable of identifying an individual. It does not include anonymised data.</p>
            <p className={styles.sectionSubtitle}>We may process certain types of personal data about you as follows:</p>
            <ol className={styles.list}>
                <li>Identity Data may include your first name, maiden name, last name, username, marital status, title, date of birth and gender.</li>
                <li>Contact Data may include your billing address, delivery address, email address and telephone numbers.</li>
                <li>Transaction Data may include details about payments between us and other details of purchases made by you.</li>
                <li>Technical Data may include your login data, internet protocol addresses, browser type and version, browser plug-in types and versions, time zone setting and location, operating system and platform and other technology on the devices you use to access this site.</li>
                <li>Profile Data may include your username and password, purchases or orders, your interests, preferences, feedback and survey responses.</li>
                <li>Usage Data may include information about how you use our website, products and services.</li>
                <li>Marketing and Communications Data may include your preferences in receiving marketing communications from us and our third parties and your communication preferences</li>
            </ol>
            <br />
            <p className={styles.sectionSubtitle}>We may also process Aggregated Data from your personal data, but this data does not reveal your identity and as such is not personal data. An example of this is where we review your Usage Data to work out the percentage of website users using a specific feature of our site. If we link the Aggregated Data with your personal data so that you can be identified from it, then it is treated as personal data.</p>
            <br />
            <br />
            <h4 className={styles.sectionTitleOrange}>Sensitive Data</h4>
            <p className={styles.sectionSubtitle}>We do not collect any Sensitive Data about you. Sensitive data refers to data that includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data. We do not collect any information about criminal convictions and offences.
                <br />
                <br />
                Where we are required to collect personal data by law, or under the terms of the contract between us and you do not provide us with that data when requested, we may not be able to perform the contract (for example, to deliver goods or services to you). If you don’t provide us with the requested data, we may have to cancel a product or service you have ordered but if we do, we will notify you at the time.
            </p>
            <br />
            <br />
            <h4 className={styles.sectionTitleOrange}>How we collect your personal data</h4>
            <p className={styles.sectionSubtitle}>We collect data about you through a variety of different methods including:</p>
            <ol className={styles.list}>
                <li>Direct interactions: You may provide data by filling in forms on our site (or otherwise) or by communicating with us by post, phone, email or otherwise, including when you:
                    <ol className={styles.list}>
                        <li>order our products or services;</li>
                        <li>create an account on our site;</li>
                        <li>subscribe to our service or publications;</li>
                        <li>request resources or marketing be sent to you;</li>
                        <li>enter a competition, prize draw, promotion or survey; or</li>
                        <li>give us feedback.</li>
                    </ol>
                </li>
                <br />
                <li>Automated technologies or interactions: As you use our site, we may automatically collect Technical Data about your equipment, browsing actions and usage patterns. We collect this data by using cookies, server logs and similar technologies. We may also receive Technical Data about you if you visit other websites that use our cookies. Please see our cookie policy for further details.</li>
                <br />
                <li>Third parties or publicly available sources: We may receive personal data about you from various third parties and public sources as set out below:
                    <ol className={styles.list}>
                        <li>analytics providers such as Google based outside the EU;</li>
                        <li>advertising networks such as Facebook based outside the EU.</li>
                    </ol>
                </li>
            </ol>
            <h4 className={styles.sectionTitleOrange}>How we use your personal data</h4>
            <p className={styles.sectionSubtitle}>We will only use your personal data when legally permitted. The most common uses of your personal data are:</p>
            <ol className={styles.list}>
                <li>Where we need to perform the contract between us.</li>
                <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                <li>Where we need to comply with a legal or regulatory obligation.</li>
            </ol>
            <p className={styles.sectionSubtitle}>Generally, we do not rely on consent as a legal ground for processing your personal data, other than in relation to sending marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by emailing us at <a href="mailto:support@devicetrackerplus.zendesk.com" className={`${styles.linkOrange}`}>support@devicetrackerplus.zendesk.com</a>.</p>
        </div>
    )
}

export default DataCollecting