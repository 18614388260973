import styles from '../../styles/policy.module.css';


const ContactDetails = () => {

    return (
        <div>
            <h4 className={styles.sectionTitleOrange}>Contact Details</h4>
            <p className={styles.sectionSubtitle}>Our full details are:</p>
            <table className={styles.contactTable}>
                <tbody>
                    <tr style={{ backgroundColor: '#F1F1F1'}}>
                        <td className={styles.contactTableRowTitle}>Full name of legal entity:</td>
                        <td className={styles.contactTableRowContent}>Boston Digital Limited</td>
                    </tr>
                    <tr>
                        <td className={styles.contactTableRowTitle}>Email address:</td>
                        <td className={styles.contactTableRowContent}>
                            <a href="mailto:support@devicetrackerplus.zendesk.com" className={`${styles.linkOrange}`}>support@devicetrackerplus.zendesk.com</a>
                        </td>
                    </tr>
                    <tr style={{ backgroundColor: '#F1F1F1'}}>
                        <td className={styles.contactTableRowTitle}>Postal address:</td>
                        <td className={styles.contactTableRowContent}>Office 6 St. Andrew Street, Hertford, England, SG14 1HZ</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <p className={styles.contactDescription}>If you are not happy with any aspect of how we collect and use your data, you have the right to complain to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues
                <a href="https://ico.org.uk/" className={styles.linkOrange}>(www.ico.org.uk)</a>. We should be grateful if you would contact us first if you do have a complaint so that we can try to resolve it for you.</p>
            <p className={styles.contactDescription}>It is very important that the information we hold about you is accurate and up to date. Please let us know if at any time your personal information changes by emailing us at <a href="mailto:support@devicetrackerplus.zendesk.com" className={styles.linkOrange}>support@devicetrackerplus.zendesk.com</a>.</p>
        </div>
    )
}

export default ContactDetails