import styles from '../../styles/policy.module.css';


const Introduction = () => {

    return (
        <div>
            <h4 className={styles.sectionTitleOrange}>Introduction</h4>
            <p className={styles.sectionSubtitle}>
                This privacy notice provides you with details of how we collect and process your personal data through your use of our site <a href="/" className={styles.linkOrange}>www.devicetrackerplus.com</a>, including any information, you may provide through our site when you purchase a product or service, sign up to our newsletter or take part in a prize draw or competition..
                <br />
                <br />
                By providing us with your data, you warrant to us that you are over 13 years of age.
                <br />
                <br />
                Boston Digital Limited is the data controller and we are responsible for your personal data (referred to as “we”, “us” or “our” in this privacy notice). Our ICO Registration Number is ZA221596.
                <br />
                <br />
                We have appointed a Data Protection Officer who oversees privacy related matters for us. If you have any questions about this privacy notice, please contact the Data Protection Officer using the details set out below.
            </p>
        </div>
    )
}

export default Introduction